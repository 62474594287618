(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import axios from '@nextcloud/axios';
export var ShareType;

(function (ShareType) {
  ShareType[ShareType["User"] = 0] = "User";
  ShareType[ShareType["Group"] = 1] = "Group";
  ShareType[ShareType["Circle"] = 7] = "Circle";
})(ShareType || (ShareType = {}));

export var Permission;

(function (Permission) {
  Permission[Permission["Admin"] = 0] = "Admin";
  Permission[Permission["Moderator"] = 1] = "Moderator";
  Permission[Permission["User"] = 2] = "User";
})(Permission || (Permission = {}));

export var Access;

(function (Access) {
  Access["Public"] = "public";
  Access["Password"] = "password";
  Access["WaitingRoom"] = "waiting_room";
  Access["WaitingRoomAll"] = "waiting_room_all";
  Access["Internal"] = "internal";
  Access["InternalRestricted"] = "internal_restricted";
})(Access || (Access = {}));

class Api {
  getUrl(endpoint) {
    return OC.generateUrl(`apps/bbb/${endpoint}`);
  }

  getRestriction() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.get(this.getUrl('restrictions/user'));
      return response.data;
    });
  }

  getRestrictions() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.get(this.getUrl('restrictions'));
      return response.data;
    });
  }

  createRestriction(groupId) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.post(this.getUrl('restrictions'), {
        groupId
      });
      return response.data;
    });
  }

  updateRestriction(restriction) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!restriction.id) {
        const newRestriction = yield this.createRestriction(restriction.groupId);
        restriction.id = newRestriction.id;
      }

      const response = yield axios.put(this.getUrl(`restrictions/${restriction.id}`), restriction);
      return response.data;
    });
  }

  deleteRestriction(id) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.delete(this.getUrl(`restrictions/${id}`));
      return response.data;
    });
  }

  isRunning(uid) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.get(this.getUrl(`server/${uid}/isRunning`));
      return response.data;
    });
  }

  insertDocument(uid, url, filename) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.post(this.getUrl(`server/${uid}/insertDocument`), {
        url,
        filename
      });
      return response.data;
    });
  }

  getRoomUrl(room) {
    let forModerator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    var _a;

    const shortener = ((_a = document.getElementById('bbb-root')) === null || _a === void 0 ? void 0 : _a.getAttribute('data-shortener')) || '';
    const token = forModerator && room.moderatorToken ? `${room.uid}/${room.moderatorToken}` : room.uid;

    if (shortener) {
      return shortener.replace(/\{user\}/g, room.userId).replace(/\{token\}/g, token);
    }

    return window.location.origin + api.getUrl(`b/${token}`);
  }

  getRooms() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.get(this.getUrl('rooms'));
      return response.data;
    });
  }

  createRoom(name) {
    let access = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Access.Public;
    let maxParticipants = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.post(this.getUrl('rooms'), {
        name,
        welcome: '',
        maxParticipants,
        record: false,
        access
      });
      return response.data;
    });
  }

  updateRoom(room) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.put(this.getUrl(`rooms/${room.id}`), room);
      return response.data;
    });
  }

  deleteRoom(id) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.delete(this.getUrl(`rooms/${id}`));
      return response.data;
    });
  }

  getRecordings(uid) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.get(this.getUrl(`server/${uid}/records`));
      return response.data;
    });
  }

  deleteRecording(id) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.delete(this.getUrl(`server/record/${id}`));
      return response.data;
    });
  }

  publishRecording(id, publish) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.post(this.getUrl(`server/record/${id}/publish`), {
        published: publish
      });
      return response.data;
    });
  }

  storeRecording(recording, path) {
    return __awaiter(this, void 0, void 0, function* () {
      const startDate = new Date(recording.startTime);
      const filename = `${encodeURIComponent(recording.name + ' ' + startDate.toISOString())}.url`;
      const url = OC.linkToRemote(`dav/files/${OC.currentUser}${path}/${filename}`);
      yield axios.put(url, `[InternetShortcut]\nURL=${recording.url}`);
      return filename;
    });
  }

  storeRoom(room, path) {
    return __awaiter(this, void 0, void 0, function* () {
      const filename = `${encodeURIComponent(room.name)}.url`;
      const url = OC.linkToRemote(`dav/files/${OC.currentUser}${path}/${filename}`);
      yield axios.put(url, `[InternetShortcut]\nURL=${this.getRoomUrl(room)}`);
      return filename;
    });
  }

  checkServer(url, secret) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.post(this.getUrl('server/check'), {
        url,
        secret
      });
      return response.data;
    });
  }

  getRoomShares(roomId) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.get(this.getUrl('roomShares'), {
        params: {
          id: roomId
        }
      });
      return response.data;
    });
  }

  createRoomShare(roomId, shareType, shareWith, permission) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.post(this.getUrl('roomShares'), {
        roomId,
        shareType,
        shareWith,
        permission
      });
      return response.data;
    });
  }

  deleteRoomShare(id) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield axios.delete(this.getUrl(`roomShares/${id}`));
      return response.data;
    });
  }

  getRecommendedShareWith() {
    let shareType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [OC.Share.SHARE_TYPE_USER, OC.Share.SHARE_TYPE_GROUP];
    return __awaiter(this, void 0, void 0, function* () {
      const url = OC.linkToOCS('apps/files_sharing/api/v1', 1) + 'sharees_recommended';
      const response = yield axios.get(url, {
        params: {
          shareType,
          itemType: 'room',
          format: 'json'
        }
      });
      return {
        users: [],
        groups: [],
        circles: [],
        exact: {
          users: response.data.ocs.data.exact.users,
          groups: response.data.ocs.data.exact.groups,
          circles: response.data.ocs.data.exact.circles || []
        }
      };
    });
  }

  searchShareWith() {
    let search = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    let shareType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [OC.Share.SHARE_TYPE_USER, OC.Share.SHARE_TYPE_GROUP];
    return __awaiter(this, void 0, void 0, function* () {
      const url = OC.linkToOCS('apps/files_sharing/api/v1', 1) + 'sharees';
      const response = yield axios.get(url, {
        params: {
          search,
          shareType,
          itemType: 'room',
          format: 'json',
          lookup: false
        }
      });
      const data = response.data.ocs.data;
      return {
        users: data.users,
        groups: data.groups,
        circles: data.circles || [],
        exact: {
          users: data.exact.users,
          groups: data.exact.groups,
          circles: data.exact.circles || []
        }
      };
    });
  }

  // @ts-ignore
  __reactstandin__regenerateByEval(key, code) {
    // @ts-ignore
    this[key] = eval(code);
  }

}

export const api = new Api();
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(__awaiter, "__awaiter", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/Common/Api.ts");
  reactHotLoader.register(ShareType, "ShareType", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/Common/Api.ts");
  reactHotLoader.register(Permission, "Permission", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/Common/Api.ts");
  reactHotLoader.register(Access, "Access", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/Common/Api.ts");
  reactHotLoader.register(Api, "Api", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/Common/Api.ts");
  reactHotLoader.register(api, "api", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/Common/Api.ts");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();