(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import axios from '@nextcloud/axios';
import { generateOcsUrl, generateUrl } from '@nextcloud/router';
import { showSuccess, showWarning, showError } from '@nextcloud/dialogs';
import '@nextcloud/dialogs/styles/toast';
import { api } from './Common/Api';
import './filelist.scss';
const mimeTypes = ['application/pdf', 'application/vnd.oasis.opendocument.presentation', 'application/vnd.oasis.opendocument.text', 'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.oasis.opendocument.graphics', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.ms-powerpoint', 'application/vnd.ms-excel', 'image/jpeg', 'image/png', 'text/plain', 'text/rtf'];

function createDirectShare(fileId) {
  var _a, _b, _c;

  return __awaiter(this, void 0, void 0, function* () {
    const url = generateOcsUrl('apps/dav/api/v1/', undefined, {
      ocsVersion: 1,
      escape: true,
      noRewrite: true
    }) + 'direct';
    const createResponse = yield axios.post(url, {
      fileId
    });
    return (_c = (_b = (_a = createResponse.data) === null || _a === void 0 ? void 0 : _a.ocs) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.url;
  });
}

function createRoomWithFile(shareUrl, filename, roomUid) {
  return __awaiter(this, void 0, void 0, function* () {
    const joinUrl = generateUrl('/apps/bbb/b/{uid}?u={url}&filename={filename}', {
      uid: roomUid,
      url: shareUrl,
      filename
    });
    window.open(joinUrl, '_blank', 'noopener,noreferrer');
  });
}

function insertDocumentToRoom(shareUrl, filename, roomUid) {
  return api.insertDocument(roomUid, shareUrl, filename);
}

function sendFile(fileId, filename, roomUid) {
  return __awaiter(this, void 0, void 0, function* () {
    const shareUrl = yield createDirectShare(fileId);
    const isRunning = yield api.isRunning(roomUid);

    if (isRunning) {
      try {
        const success = yield insertDocumentToRoom(shareUrl, filename, roomUid);

        if (success) {
          showSuccess(t('bbb', 'The file "{filename}" was uploaded to your room.', {
            filename
          }));
        } else {
          showWarning(t('bbb', 'The file "{filename}" could not be uploaded to your room.', {
            filename
          }));
        }
      } catch (_a) {
        showError(t('bbb', 'The file "{filename}" could not be uploaded to your room. Maybe your BigBlueButton server does not support this action.', {
          filename
        }));
      }
    } else {
      createRoomWithFile(shareUrl, filename, roomUid);
    }
  });
}

function openDialog(fileId, filename) {
  return __awaiter(this, void 0, void 0, function* () {
    const initContent = '<div id="bbb-file-action"><span className="icon icon-loading-small icon-visible"></span></div>';
    const title = t('bbb', 'Send file to BBB');
    yield OC.dialogs.message(initContent, title, 'none', -1, undefined, true, true);
    const rooms = yield api.getRooms();
    const container = $('#bbb-file-action').empty();
    const table = $('<table>').appendTo(container);
    table.attr('style', 'margin-top: 1em; width: 100%;');

    for (const room of rooms) {
      const row = $('<tr>');
      const button = $('<button>');
      button.text(room.running ? t('bbb', 'Send to') : t('bbb', 'Start with'));
      button.addClass(room.running ? 'success' : 'primary');
      button.attr('type', 'button');
      button.on('click', ev => {
        ev.preventDefault();
        table.find('button').prop('disabled', true);
        $(ev.target).addClass('icon-loading-small');
        sendFile(fileId, filename, room.uid).then(() => {
          container.parents('.oc-dialog').find('.oc-dialog-close').trigger('click');
        });
      });
      row.append($('<td>').append(button));
      row.append($('<td>').attr('style', 'width: 100%;').text(room.name));
      row.appendTo(table);
    }

    if (rooms.length > 0) {
      const description = t('bbb', 'Please select the room in which you like to use the file "{filename}".', {
        filename
      });
      container.append(description);
      container.append(table);
    } else {
      container.append($('p').text(t('bbb', 'No rooms available!')));
    }
  });
}

function registerFileAction(fileActions, mime) {
  fileActions.registerAction({
    name: 'bbb',
    displayName: t('bbb', 'Send to BBB'),
    mime,
    permissions: OC.PERMISSION_SHARE,
    icon: OC.imagePath('bbb', 'app-dark.svg'),
    actionHandler: (fileName, context) => {
      console.log('Action handler');
      openDialog(context.fileInfoModel.id, fileName);
    }
  });
}

const BBBFileListPlugin = {
  ignoreLists: ['trashbin'],

  attach(fileList) {
    if (this.ignoreLists.includes(fileList.id) || !OC.currentUser) {
      return;
    }

    mimeTypes.forEach(mime => registerFileAction(fileList.fileActions, mime));
  }

};
OC.Plugins.register('OCA.Files.FileList', BBBFileListPlugin);
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(__awaiter, "__awaiter", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/filelist.ts");
  reactHotLoader.register(mimeTypes, "mimeTypes", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/filelist.ts");
  reactHotLoader.register(createDirectShare, "createDirectShare", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/filelist.ts");
  reactHotLoader.register(createRoomWithFile, "createRoomWithFile", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/filelist.ts");
  reactHotLoader.register(insertDocumentToRoom, "insertDocumentToRoom", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/filelist.ts");
  reactHotLoader.register(sendFile, "sendFile", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/filelist.ts");
  reactHotLoader.register(openDialog, "openDialog", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/filelist.ts");
  reactHotLoader.register(registerFileAction, "registerFileAction", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/filelist.ts");
  reactHotLoader.register(BBBFileListPlugin, "BBBFileListPlugin", "/home/zak/Documents/arawa/codes/nextcloud-docker-dev/workspace/server/apps-extra/cloud_bbb/ts/filelist.ts");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();